import './components/Home'
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Home from './components/Home';
import NotFound from './components/NotFound';

class App extends React.Component {
  render() {
    document.body.style = "background: #303030"

    const theme = createTheme({
      palette: {
        type: "dark",
      }
    });

    const style = {
      color: "#fff",
      fontSize: "calc(10px + 2vmin)",
    };

    return (
      <ThemeProvider theme={theme} >
        <div style={style}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
