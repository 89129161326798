import cover from '../assets/cover.jpg';
import logo from '../assets/naito.jpg';
import kiks from '../assets/kiks.mp4'
import clock from '../assets/clock.mp4'
import epaper from '../assets/epaper.mp4'
import cpp from '../assets/c-plusplus.svg'
import python from '../assets/python.svg'
import javascript from '../assets/javascript.svg'
import traps_circuit from '../assets/traps_circuit.png'
import nnabla from '../assets/nnabla.png'
import React, { Fragment } from 'react';
import { Avatar, Backdrop, Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Modal, Table, TableBody, TableCell, TableRow, Typography, Zoom } from "@material-ui/core";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import SchoolIcon from '@material-ui/icons/School';
import GroupIcon from '@material-ui/icons/Group';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';

function HomeGridCard(props) {
  const root_style = { marginBottom: "1em" };
  const style = props.style === null ? root_style : Object.assign(root_style, props.style);
  return (
    <Grid item xs={12} style={style}>
      <Card elevation={3} style={{ textAlign: "left" }}>
        <CardContent>
          <Typography variant="h4" style={{ marginBottom: "0.5em" }}>{props.title}</Typography>
          {props.children}
        </CardContent>
      </Card>
    </Grid >
  );
}

function WorkCard(props) {
  const root_style = { display: "flex", width: "100%", height: "7em" };
  const style = props.style === null ? root_style : Object.assign(root_style, props.style);

  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Card onClick={() => setOpen(true)} raised={true} style={style}>
        <CardActionArea style={{ display: "flex", justifyContent: "left" }}>
          <CardContent align="left" style={{ flex: "1 0 50%" }}>
            <Typography variant="h5">{props.title}</Typography>
            <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
          </CardContent>
          <CardMedia component={/\.(mp4|webm|ogg)$/.test(props.src) ? "video" : "img"} muted autoPlay loop style={{ minWidth: "80%", minHeight: "100%" }} src={props.src} />
        </CardActionArea>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Zoom in={open}>
          <Card style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
            width: "700px",
            maxWidth: "90%",
            outline: "0"
          }}>
            <CardContent>
              <Typography variant="h5">{props.title}</Typography>
              <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
              {props.children}
            </CardContent>
            <Button onClick={() => setOpen(false)} style={{ width: "100%" }}><CloseIcon />CLOSE</Button>
          </Card>
        </Zoom>
      </Modal>
    </Fragment>
  );
}

function SkillIcon(props) {
  const root_style = { width: "3em", height: "3em", position: "relative", borderRadius: "50%", textAlign: "center", backgroundColor: "white" };
  const style = props.style === null ? root_style : Object.assign(root_style, props.style);
  return (
    <div style={style}>
      <img alt="skill icon" style={{ width: "60%", position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)" }} src={props.src} />
    </div>
  );
}

class Home extends React.Component {
  render() {
    const cover_style = {
      height: "400px",
      maxHeight: "40vh",
      backgroundImage: `url(${cover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0% 100%"
    };
    const home_style = {
      textAlign: "center",
      width: "98%",
      maxWidth: "600px",
      margin: "0 auto",
      paddingTop: "250px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const career_rows = [
      { year: 2017, border: true, text: "高専生向けNI myRIO組み込みシステム開発コンテスト 2017 優秀賞" },
      { year: 2018, border: true, text: "RoboCup 2018 Montreal Small Size League 9th" },
      { year: 2019, border: false, text: "RoboCup 2019 Sydney Small Size League 8th" },
      { year: null, border: true, text: "ロボカップジャパンオープン2019ながおか Small Size League 3位" },
      { year: 2020, border: false, text: "電気学会関西支部 令和元年度高専卒業研究発表会 論文発表賞" },
      { year: null, border: true, text: "豊田工業高等専門学校 電気・電子システム工学科卒業" },
      { year: 2021, border: false, text: "ロボカップ研究賞（International Conference on Engineering and Industrial Technology 2020）" },
      { year: null, border: false, text: "RoboCup 2021 Worldwide Small Size League Virtual Tournament 5th Hardware Challenge 4th" },
      { year: null, border: true, text: "RoboCup Asia-Pacific 2021 Aichi Japan Small Size League 3rd, 日本ロボット学会賞" },
      { year: 2022, border: false, text: "豊田工業高等専門学校 専攻科電子機械工学専攻修了" },
      { year: 2024, border: true, text: "奈良先端科学技術大学院大学 情報科学領域 博士前期課程修了" },
    ];

    return (
      <div style={cover_style} >
        <div style={home_style}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "1em" }}>
              <Container style={{ alignItems: "center", justifyContent: "center" }}>
                <Zoom in={true} timeout={700}>
                  <Avatar src={logo} style={{ width: "40%", height: "40%", margin: "0 auto" }} />
                </Zoom>
              </Container>
              <Typography variant="h3">Yusei Naito/内藤 優星</Typography>
              <Container style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Zoom in={true} timeout={1100}>
                  <Link href="https://twitter.com/vitz787b">
                    <Avatar><TwitterIcon /></Avatar>
                  </Link>
                </Zoom>
                <Zoom in={true} timeout={1200}>
                  <Link href="https://www.facebook.com/profile.php?id=100045887501495">
                    <Avatar style={{ marginLeft: "20px", marginRight: "20px" }}><FacebookIcon /></Avatar>
                  </Link>
                </Zoom>
                <Zoom in={true} timeout={1300}>
                  <Link href="https://github.com/YuseiNaito">
                    <Avatar><GitHubIcon /></Avatar>
                  </Link>
                </Zoom>
              </Container>
            </Grid>
            <Zoom in={true} timeout={500}>
              <HomeGridCard title="About">
                <List>
                  <ListItem>
                    <ListItemIcon><Avatar><SchoolIcon /></Avatar></ListItemIcon>
                    <ListItemText primary="NAIST" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><Avatar><GroupIcon /></Avatar></ListItemIcon>
                    <ListItemText primary="RoboCup SSL Team TRAPS" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><Avatar><StarIcon /></Avatar></ListItemIcon>
                    <ListItemText primary="ロボット，京都アニメーション作品（響け！ユーフォニアム，リズと青い鳥），アニメ聖地巡礼" />
                  </ListItem>
                </List>
              </HomeGridCard>
            </Zoom>
            <Zoom in={true} timeout={600}>
              <HomeGridCard title="Career">
                <Table size="small">
                  <TableBody>
                    {career_rows.map((row) => {
                      return (
                        <TableRow key={row.text}>
                          <TableCell align="right" style={{ border: 0 }}>{row.year}</TableCell>
                          <TableCell align="left">{row.text}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </HomeGridCard>
            </Zoom>
            <Zoom in={true} timeout={700}>
              <HomeGridCard title="Works">
                <WorkCard title="RoboCup SSL Team TRAPS" subtitle="Programmer" src={traps_circuit} style={{ marginBottom: "0.5em" }}>
                  <Typography variant="body1">
                    RoboCupとは，西暦2050年までに人間のサッカーワールドカップ優勝チームに勝利できる人型ロボットのチームを作ることを目標とし，その過程で生まれるロボット工学や人工知能の技術を社会に還元することを目的とした研究プロジェクトです．
                  </Typography>
                  <Typography variant="body1">
                    RoboCup SSLチーム「TRAPS」は、豊田高専KIKS出身者を中心に、様々なバックグラウンドを持つメンバーで結成されました。
                    KIKSをはじめとするSSLチームが築いてきた資産、メンバーが各所で磨き上げてきたスキルを受け（trap）、SSL発展やRoboCupの目標「西暦2050年までに、サッカーの世界チャンピオンチームに勝てる、自律移動のヒューマノイドロボットのチームを作る」に向けた次の一手に繋げることを目指しています。
                  </Typography>
                  <Button href="https://traps-official.web.app/" style={{ width: "100%", backgroundColor: '#212121' }}>TRAPS公式サイト</Button>
                </WorkCard>
                <WorkCard title="RoboCup SSL Team KIKS" subtitle="Leader/Programmer" src={kiks} style={{ marginBottom: "0.5em" }}>
                  <Typography variant="body1">
                    RoboCupとは，西暦2050年までに人間のサッカーワールドカップ優勝チームに勝利できる人型ロボットのチームを作ることを目標とし，その過程で生まれるロボット工学や人工知能の技術を社会に還元することを目的とした研究プロジェクトです．
                  </Typography>
                  <Typography variant="body1">
                    KIKS が参加する Soccer Small Size League（SSL）は，直径18cm以下のロボットが最大11台対11台でサッカーをおこなうリーグで，天井に取り付けられたカメラから得られる映像をもとに試合が進行します は，直径18cm以下のロボットが最大11台対11台でサッカーをおこなうリーグで，天井に取り付けられたカメラから得られる映像をもとに試合が進行します．
                  </Typography>
                  <iframe style={{ width: "100%", height: "20em", maxHeight: "30vh" }} src="https://www.youtube.com/embed/m-CIKu6gGd8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </WorkCard>
                <WorkCard title="魔法の時計(GakuseiGuild)" subtitle="Programmer" src={clock} style={{ marginBottom: "0.5em" }}>
                  <Typography variant="body1">
                    現在進行中のプロジェクトです．技術の力で古時計を魔法の時計にします．
                  </Typography>
                  <video style={{ maxWidth: "75%", height: "20em", maxHeight: "30vh", paddingRight: "10px", boxSizing: "border-box" }} muted controls src={clock} />
                  <video style={{ maxWidth: "25%", height: "20em", maxHeight: "30vh" }} muted controls src={epaper} />
                </WorkCard>
              </HomeGridCard>
            </Zoom>
            <Zoom in={true} timeout={800}>
              <HomeGridCard title="Skills">
                <div style={{ display: "flex" }}>
                  <SkillIcon src={cpp} style={{ margin: "0.2em" }} />
                  <SkillIcon src={python} style={{ margin: "0.2em" }} />
                  <SkillIcon src={nnabla} style={{ margin: "0.2em" }} />
                  <SkillIcon src={javascript} style={{ margin: "0.2em" }} />
                </div>
              </HomeGridCard>
            </Zoom>
            <Typography variant="body1" style={{ margin: "0 0 0 auto" }}>© Yusei Naito 2024</Typography>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Home;
